/**
 * Calculates the Net Present Value of a given initial investment
 * cost and an array of cash flow values with the specified discount rate.
 * @param {number} rate - The discount rate percentage
 * @param {number} initialCost - The initial investment
 * @param {array} cashFlows - An array of future payment amounts
 * @return {number} The calculated Net Present Value
 */
export const getNPV = (rate, _initialCost, cashFlows) => {
  let sum = 0.0;
  cashFlows.forEach((income, index) => {
    sum = sum + income / Math.pow(1 + rate, index + 1);
  });
  return sum;
};

export const getNextMonthDateFrom = (_monthsToSum, date, previous) => {
  let daysToSum = 0;
  let currentMonthLastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  );
  let nextMonthLastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  if (
    date.getMonth() === 1 &&
    previous.getDate() > date.getDate() &&
    previous.getDate() <= nextMonthLastDay.getDate()
  ) {
    date.setDate(previous.getDate());
  }
  if (previous.getDate() > nextMonthLastDay.getDate()) {
    daysToSum =
      nextMonthLastDay.getDate() +
      (currentMonthLastDay.getDate() - date.getDate());
  } else {
    if (previous.getDate() < date.getDate()) {
      if (previous.getMonth() === 1) {
        daysToSum = nextMonthLastDay.getDate();
      } else {
        daysToSum =
          nextMonthLastDay.getDate() +
          (currentMonthLastDay.getDate() - nextMonthLastDay.getDate());
      }
    } else {
      daysToSum =
        nextMonthLastDay.getDate() +
        (currentMonthLastDay.getDate() - nextMonthLastDay.getDate());
    }
  }

  if (date.getMonth() != 0 && date.getMonth() != 1 && date.getMonth() != 2) {
    previous = new Date(date);
  }
  const returnValue = [
    new Date(date.setDate(date.getDate() + daysToSum)),
    previous,
  ];
  // console.log(returnValue);
  return returnValue;
};

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  // return months <= 0 ? 0 : months;
  return months;
};

// export const monthDiff = (dt1, dt2) => {
//   var diff = (dt2.getTime() - dt1.getTime()) / 1000;
//   diff /= 60 * 60 * 24 * 7 * 4;
//   return Math.abs(Math.round(diff));
// };
