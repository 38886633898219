export const findInStorage = (storageName, criteria, value) => {
  var result = null;
  const storageFound = JSON.parse(localStorage.getItem(storageName));
  if (storageFound) {
    result = storageFound.find(
      (iterable) => iterable[criteria].toString() == value.toString()
    );
  }
  return result;
};

export const searchInStorage = (storageName, criteria, value) => {
  var result = null;
  const storageFound = JSON.parse(localStorage.getItem(storageName));
  if (storageFound) {
    result = storageFound.filter((iterable) => iterable[criteria] == value);
  }
  return result;
};
