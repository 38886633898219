import { toggleLoader } from "packs/lib/loader";
import { serverRequest } from "packs/lib/callbacable";
import { findInStorage, searchInStorage } from "packs/lib/storable";
import { numberToCurrency } from "packs/lib/formateable";
import { getNPV, getNextMonthDateFrom, monthDiff } from "packs/lib/calculable";
import { mobileAndTabletCheck } from "packs/lib/deviceable";
import {
  productPaymentPlanForm,
  productTableRow,
  reserveRowContent,
  installmentRowContent,
} from "packs/components/quotations/product_payment_plan_form";

document.addEventListener("DOMContentLoaded", function () {
  const fillCustomerFields = (customerData) => {};
  localStorage.setItem("products", null);
  localStorage.setItem("constructions", null);
  localStorage.setItem("productsQuotated", null);
  localStorage.setItem("cashFlows", []);
  localStorage.setItem("customer_email", $("#customer-email").val());
  localStorage.setItem("customer_cellphone", $("#customer-cellphone").val());
  localStorage.setItem("customer_dni", $("#customer-dni").val());
  localStorage.setItem("current_date", new Date());

  const searchForCustomerData = (field, value) => {
    serverRequest(
      "/customers/search_by",
      "GET",
      { field: field, value: value },
      {},
      null,
      null
    ).then(
      (data) => {
        toggleLoader();
        M.toast({ html: "Cliente encontrado" });
        setTimeout(() => {
          window.location.replace(
            "/quotations/new?field=" + field + "&value=" + data.customer[field]
          );
        }, 200);
      },
      (error) => {
        M.toast({ html: "Cliente no encontrado" });
      }
    );
  };

  const searchForProjectData = (projectId) => {
    serverRequest(
      "/constructions/search_by",
      "GET",
      { project_id: projectId },
      {},
      null,
      null
    ).then(
      (data) => {
        M.toast({ html: "Construcciones encontradas" });
        $("#construction-select").find("option").remove().end();
        $("#construction-select").append(
          "<option value='0' disabled selected>Selecciona construcción</option>"
        );
        $.each(data.constructions, function (_, construction) {
          $("#construction-select").append(
            "<option value=" +
              construction.id +
              ">" +
              construction.name +
              "</option>"
          );
        });
        $("#construction-select").sm_select();
        window.localStorage.setItem(
          "constructions",
          JSON.stringify(data.constructions)
        );
      },
      (error) => {
        M.toast({ html: "Construcciones no encontradas", classes: "error" });
      }
    );
  };

  const prexistanceProducts = () => {
    var productIds = [];
    $("#product-table-body tr").each((_index, row) => {
      productIds.push(row.dataset.product);
    });
    var productsQuotated = JSON.parse(localStorage.getItem("productsQuotated"));
    if (productsQuotated === undefined || productsQuotated === null) {
      productsQuotated = [];
    }
    productIds.forEach((productId) => {
      bindProductHooks(productId);
      const product = findInStorage("products", "id", productId);
      productsQuotated.push(product);
    });
    window.localStorage.setItem(
      "productsQuotated",
      JSON.stringify(productsQuotated)
    );
    $(".btn-product-table").on("click", function (event) {
      var productId = event.target.dataset.product;
      removeProduct(productId);
    });
  };

  const searchForProductsData = (constructionId, fromEdit) => {
    serverRequest(
      "/products/search_by",
      "GET",
      { construction_id: constructionId },
      {},
      null,
      null
    ).then(
      (data) => {
        M.toast({ html: "Productos encontrados" });
        $("#product-select").find("option").remove().end();

        $("#product-select").append(
          "<option selected disabled>Selecciona productos</option>"
        );
        $.each(data.products, function (_, product) {
          $("#product-select").append(
            "<option value=" +
              product.id +
              ">" +
              product.category.translation +
              " " +
              product.concatenate_name +
              "</option>"
          );
        });
        $("#product-select").sm_select();
        window.localStorage.setItem("products", JSON.stringify(data.products));
        if (fromEdit) {
          prexistanceProducts();
        }
      },
      (error) => {
        M.toast({ html: "Productos no encontrados", classes: "error" });
      }
    );
  };

  const canAddProduct = (productId) => {
    var canAdd = true;
    var productsQuotated = JSON.parse(
      window.localStorage.getItem("productsQuotated")
    );
    if (productsQuotated) {
      const productToAdd = findInStorage("products", "id", productId);
      const existanceProduct = findInStorage(
        "productsQuotated",
        "id",
        productId
      );
      if (existanceProduct) {
        canAdd = false;
        M.toast({
          html: `El producto ${productToAdd.concatenate_name} ya se encuentra agregado a la cotización`,
        });
      } else {
        if (productToAdd.kind_of_product == "principal") {
          if (
            productsQuotated.find(
              (product) => product.kind_of_product === "principal"
            )
          ) {
            canAdd = false;
            M.toast({
              html: `No puedes incluir más de 1 producto principal en una cotización`,
            });
          }
        }
      }
    }
    return canAdd;
  };

  const addProduct = (productId, alreadyIterated) => {
    if (canAddProduct(productId)) {
      var product = findInStorage("products", "id", productId);
      if (product) {
        if (
          product.product_price.list_price == null ||
          product.product_price.list_price == undefined
        ) {
          M.toast({
            html: "Error. El producto no tiene precio especificado",
          });
        } else {
          var productsQuotated = JSON.parse(
            window.localStorage.getItem("productsQuotated")
          );
          if (productsQuotated == null) {
            productsQuotated = [];
          }
          productsQuotated.push(product);
          window.localStorage.setItem(
            "productsQuotated",
            JSON.stringify(productsQuotated)
          );
          $("#product-table-body").append(productTableRow(product));
          $("#product-display-collapsible").append(
            productPaymentPlanForm(product)
          );

          $(".btn-product-table").on("click", function (event) {
            var productId = event.target.dataset.product;
            removeProduct(productId);
          });
          var elems = document.querySelectorAll(
            ".fixed-action-btn-product-nvp"
          );
          var instances = M.FloatingActionButton.init(elems, {
            hoverEnabled: false,
            direction: "left",
            toolbarEnabled: true,
          });
          M.toast({ html: "Producto añadido" });
        }
      } else {
        M.toast({ html: "No se pudo añadir el producto" });
      }
      bindProductHooks(productId);
      bindTypeOfFootHooks(productId);
      if (alreadyIterated == false) {
        checkProductColigation(product);
      }
    }
  };

  const checkProductColigation = (product) => {
    if (product.father_id && product.father_id != "") {
      var fatherProduct = findInStorage("products", "id", product.father_id);
      if (fatherProduct) {
        addProduct(fatherProduct.id, true);
      }
    }
    if (product.id && product.id != "") {
      var sonProducts = searchInStorage("products", "father_id", product.id);
      if (sonProducts && sonProducts.length > 0) {
        sonProducts.forEach((product) => {
          addProduct(product.id, true);
        });
      }
    }
  };

  const removeProduct = (productId) => {
    var product = findInStorage("products", "id", productId);
    if (product) {
      var productsQuotated = JSON.parse(
        window.localStorage.getItem("productsQuotated")
      );
      if (productsQuotated == null) {
        productsQuotated = [];
      }
      productsQuotated = productsQuotated.filter(
        (product) => product.id != productId
      );
      window.localStorage.setItem(
        "productsQuotated",
        JSON.stringify(productsQuotated)
      );
      $("#product-table-body")
        .find("#product" + product.id)
        .remove()
        .end();
      $("#product-display-collapsible")
        .find(".product" + product.id)
        .remove()
        .end();
      M.toast({ html: "Producto removido" });
    } else {
      if ($("#product-table-body").find("#product" + productId)) {
        $("#product-table-body")
          .find("#product" + productId)
          .remove()
          .end();
        $("#product-display-collapsible")
          .find(".product" + productId)
          .remove()
          .end();
        M.toast({ html: "Producto removido" });
      } else {
        M.toast({ html: "No se puede remover el producto" });
      }
    }
  };

  // Execute a function when the user releases a key on the keyboard
  $(".customer-searcher").on("keydown", function (event) {
    var inputValue = event.target.value;
    const searchField = event.target.dataset.searchField;

    var currentCustomerFieldData = localStorage.getItem(
      `customer_${searchField}`
    );

    if (event.which === 13 && currentCustomerFieldData !== inputValue) {
      localStorage.setItem(`customer_${searchField}`, inputValue);
      searchForCustomerData(searchField, inputValue);
    }
    if (
      event.which === 9 &&
      currentCustomerFieldData !== inputValue &&
      mobileAndTabletCheck()
    ) {
      localStorage.setItem(`customer_${searchField}`, inputValue);
      searchForCustomerData(searchField, inputValue);
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  $("#project-select").on("change", function () {
    var selectedProject = $("#project-select").val();
    searchForProjectData(selectedProject);
  });

  $("#construction-select").on("change", function () {
    var selectedConstruction = $("#construction-select").val();
    searchForProductsData(selectedConstruction, false);
  });

  $("#product-select").on("change", function () {
    var selectedProduct = $("#product-select").val();
    addProduct(selectedProduct, false);
  });

  const hookActions = (input) => {
    var inputValue = input.value;
    var inputAction = input.dataset.action;
    var productId = input.dataset.product;
    switch (inputAction) {
      case "change-sale-value":
        changeSaleValue(input, inputValue, productId);
        break;
      case "change-discount":
        changeDiscount(input, inputValue, productId);
        break;
      case "change-sale-price":
        changeSalePrice(input, inputValue, productId);
        break;
      case "change-foot":
        changeFoot(input, inputValue, productId);
        break;
      case "change-installments":
        changeInstallments(input, inputValue, productId);
        break;
      default:
        break;
    }
  };

  const bindProductHooks = (productId) => {
    $(`.hook-${productId}`).on("keydown", function (event) {
      var input = event.target;
      if (event.which === 13 || event.which === 9) {
        hookActions(input);
      }
      if (event.which === 13) {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    $(`.hook-${productId}`).on("change", (event) => {
      var input = event.target;
      hookActions(input);
    });

    $(".date-distribution-changer-" + productId).on("keydown", (event) => {
      productDateDistributionChanger(event, productId);
    });

    $(".per-installment-amount-changer-" + productId).on("keydown", (event) => {
      productInstallmentAmountChanger(event, productId);
    });

    $(".installment-from-month-changer-" + productId).on("keydown", (event) => {
      productInstallmentAmountChanger(event, productId);
    });

    $(".installment-till-month-changer-" + productId).on("keydown", (event) => {
      productInstallmentAmountChanger(event, productId);
    });

    $(".installments-percent-distribution-changer-" + productId).on(
      "keydown",
      (event) => {
        productInstallmentsPercentDistributionChanger(event, productId);
      }
    );
  };

  const bindTypeOfFootHooks = (productId) => {
    $(
      `input[name="quotated_products_attributes[][type-of-foot-${productId}]"]`
    ).on("click", (event) => {
      var selectedRadioButton = event.target;
      var productId = selectedRadioButton.dataset.product;
      var typeOfFoot = selectedRadioButton.dataset.footType;

      if (typeOfFoot === "percent") {
        $(`.product-foot-percent-${productId}-container`).removeClass("hide");
        $(`.product-foot-amount-${productId}-container`).addClass("hide");
      } else {
        $(`.product-foot-percent-${productId}-container`).addClass("hide");
        $(`.product-foot-amount-${productId}-container`).removeClass("hide");
      }
    });
    $(`.product-foot-percent-${productId}`).trigger("change");
    changeNvpDisplay([], productId, true);
  };

  const changeDiscount = (input, value, productId) => {
    var maxDiscount = $(input).attr("max");
    if (parseFloat(maxDiscount) < parseFloat(value)) {
      $(input).val(maxDiscount);
      value = maxDiscount;
      M.toast({
        html: `No puedes superar el descuento de ${maxDiscount}%`,
      });
    }
    var productBasePrice = $(`.product-base-price-${productId}`).val();
    var discountedPrice = productBasePrice - productBasePrice * (value / 100);
    $(`.product-sale-price-${productId}`).val(discountedPrice.toFixed(2));
    $(`.product-foot-percent-${productId}`).trigger("change");
  };

  const changeSalePrice = (input, value, productId) => {
    var productBasePrice = $(`.product-base-price-${productId}`).val();
    var tempDiscount = 100 - (100 / productBasePrice) * value;
    $(`.product-discount-${productId}`).val(tempDiscount.toFixed(2));
    $(`.product-foot-percent-${productId}`).trigger("change");
  };

  const changeFoot = (input, value, productId) => {
    var productFinalPrice = $(`.product-sale-price-${productId}`).val();
    var foot = calcFoot(productFinalPrice, value, input.dataset.typeOfFoot);
    $(`.product-foot-percent-${productId}`).val(
      parseFloat(foot.percent).toFixed(2)
    );
    $(`.product-foot-amount-${productId}`).val(
      parseFloat(foot.amount).toFixed(2)
    );
    var product = findInStorage("products", "id", productId);
    if (foot.percent >= product.construction.project.minimum_foot_percent) {
      $(`.product-installments-${productId}-container`).removeClass("hide");
      $(`.product-installments-${productId}`).trigger("change");
    } else {
      if (!$(`.product-installments-${productId}-container`).hasClass("hide")) {
        $(`.product-installments-${productId}-container`).addClass("hide");
      }
      $(`.product-payment-installments-container-${productId}`).addClass(
        "hide"
      );
    }
  };

  const changeInstallments = (input, value, productId) => {
    var maxInstallments = $(input).attr("max");
    if (parseFloat(value) > parseFloat(maxInstallments)) {
      $(input).val(maxInstallments);
      value = maxInstallments;
      M.toast({
        html: `No puedes superar el límite de ${maxInstallments} cuotas`,
      });
    }
    var installments = parseFloat(value);
    if (installments < 1) {
      $(input).val(1);
      $(`.product-payment-installments-container-${productId}`).addClass(
        "hide"
      );
    } else {
      changeInstallmentsDistribution(
        false,
        installments,
        productId,
        0,
        0,
        null
      );
    }
  };

  const changeNvpDisplay = (cashFlows, productId, firstLoad = false) => {
    var defaultNvpHolder = 2;
    if (firstLoad) {
      var preloadCashFlows = localStorage.getItem("cashFlows");
      if (
        cashFlows.length === 0 &&
        preloadCashFlows &&
        preloadCashFlows != ""
      ) {
        cashFlows = JSON.parse(preloadCashFlows).map((obj) => obj.amount);
      }
      defaultNvpHolder = 1;
    } else {
      cashFlows[0] =
        parseFloat(cashFlows[0]) +
        parseFloat($(`.product-${productId}-reserve-amount`).val());
    }
    const product = findInStorage("products", "id", productId);
    const calculatedNVP = getNPV(
      (1 + product.construction.project.anual_nvp_rate / 100) ** (1 / 12) - 1,
      // product.construction.project.anual_nvp_rate / 12,
      0,
      cashFlows
    );
    var productInstallmentTotal = 0;
    $.each($(".product-payment-months"), (_, input) => {
      productInstallmentTotal =
        productInstallmentTotal + parseFloat($(input).val());
    });
    productInstallmentTotal =
      productInstallmentTotal +
      parseFloat($(`.product-${productId}-reserve-amount`).val());
    $(`.product-total-installment-price-${productId}`).val(
      numberToCurrency(productInstallmentTotal)
    );
    const preloadedVNA1 = Number(
      $(`.product-nvp-price-${productId}-1`)
        .val()
        .replace(/[^0-9.-]+/g, "")
    );
    const listPrice = Number(
      product.product_price.list_price.toString().replace(/[^0-9.-]+/g, "")
    );
    if (
      (defaultNvpHolder == 1 && listPrice == preloadedVNA1) ||
      defaultNvpHolder == 2
    ) {
      $(`.product-nvp-price-${productId}-${defaultNvpHolder}`).val(
        numberToCurrency(calculatedNVP)
      );
      localStorage.setItem(
        `product-nvp-price-${productId}-${defaultNvpHolder}`,
        calculatedNVP
      );
    }
    changeNvpDifference(productId);
  };

  const changeNvpDifference = (productId) => {
    const nvp1 = parseFloat(
      localStorage.getItem(`product-nvp-price-${productId}-1`)
    );
    const nvp2 = parseFloat(
      localStorage.getItem(`product-nvp-price-${productId}-2`)
    );
    $(`.product-total-installment-remain-price-${productId}`).val(
      numberToCurrency(nvp2 - nvp1)
    );
  };

  const calcInstallmentAmount = (
    finalPrice,
    footAmount,
    installmentsAmount,
    currentMonth
  ) => {
    var value = 0;
    if (parseFloat(installmentsAmount) === 1) {
      value = parseFloat(finalPrice);
    } else {
      if (currentMonth === installmentsAmount && !(finalPrice === footAmount)) {
        value = finalPrice - footAmount;
      } else {
        value = footAmount / (installmentsAmount - 1);
      }
    }
    return value;
  };

  const calcFoot = (finalPrice, footAmount, selectedFootType) => {
    var value = {};
    if (selectedFootType === "percent") {
      value.percent = footAmount;
      value.amount = finalPrice * (footAmount / 100);
    } else {
      value.amount = footAmount;
      value.percent = (100 / finalPrice) * footAmount;
    }
    return value;
  };

  const selectedKindOfFootFor = (productId) => {
    var kind = null;
    var selectedOption = $(
      `input[name="quotated_products_attributes[][type-of-foot-${productId}]"]:checked`
    );
    if (selectedOption) {
      kind = selectedOption.val();
    }
    return kind;
  };

  const changeInstallmentsDistribution = (
    fromInstallmentInput,
    installments,
    productId,
    currentMonth,
    amount,
    fromDate,
    firstInstallment,
    lastInstallment
  ) => {
    if (installments > 0) {
      var cashFlows = [];
      if (fromInstallmentInput) {
        cashFlows = JSON.parse(localStorage.getItem("cashFlows"));
        $.each(cashFlows, (_, flow) => {
          if (flow.month === parseFloat(currentMonth)) {
            flow.amount = amount;
          }
        });
      } else {
        var selectedFootType = selectedKindOfFootFor(productId);
        var productFootStablished = $(
          `.product-foot-${selectedFootType}-${productId}`
        ).val();
        $(`.product-payment-installments-container-${productId}`).removeClass(
          "hide"
        );
        $(`#product-calc-body-${productId}`).find("tr").remove().end();
        var productFinalPrice = $(`.product-sale-price-${productId}`).val();
        var foot = null;
        let firstInstallmentAmount = null;
        let lastInstallmentAmount = null;
        if (firstInstallment && lastInstallment) {
          firstInstallmentAmount = productFinalPrice * (firstInstallment / 100);
          lastInstallmentAmount = productFinalPrice * (lastInstallment / 100);
          foot = {
            amount:
              productFinalPrice -
              firstInstallmentAmount -
              lastInstallmentAmount,
            percent: 100 - firstInstallment - lastInstallment,
          };
        } else {
          foot = calcFoot(
            productFinalPrice,
            productFootStablished,
            selectedFootType
          );
        }
        var previousDate = new Date(localStorage.getItem("current_date"));
        var currentDate = new Date(localStorage.getItem("current_date"));
        // var currentDate = new Date(
        //   previousDate.getFullYear(),
        //   previousDate.getMonth() + 1,
        //   31
        // );
        if (fromDate) {
          currentDate = fromDate;
        }
        $(`#product-calc-body-${productId}`).append(
          reserveRowContent(0, currentDate, 0, productId)
        );
        $(`.product-${productId}-reserve-amount`).on("keydown", (event) => {
          changedReserveAmount(event, productId);
        });
        if (installments <= 1) {
          for (var i = 1; i <= installments; i++) {
            var month = i;
            if (month > 1) {
              [currentDate, previousDate] = getNextMonthDateFrom(
                1,
                currentDate,
                previousDate
              );
            }
            var dateInstallmentAmount = 0;
            dateInstallmentAmount = calcInstallmentAmount(
              productFinalPrice,
              foot.amount,
              installments,
              month
            );
            cashFlows.push({ month: month, amount: dateInstallmentAmount });
            $(`#product-calc-body-${productId}`).append(
              installmentRowContent(
                month,
                currentDate,
                dateInstallmentAmount,
                productId
              )
            );
            bindChangeMonthAmountFor(month, productId);
          }
        } else {
          var month = 1;
          var acountableInstallments = installments;
          let evaluableProductFinalPrice = productFinalPrice;
          if (firstInstallment && lastInstallment) {
            acountableInstallments = acountableInstallments - 1;
            evaluableProductFinalPrice =
              productFinalPrice -
              firstInstallmentAmount -
              lastInstallmentAmount;
          }
          for (month; month <= installments; month++) {
            if (month > 1) {
              [currentDate, previousDate] = getNextMonthDateFrom(
                1,
                currentDate,
                previousDate
              );
            }
            if (firstInstallment && month == 1) {
              let currentMonthAmount =
                productFinalPrice * (firstInstallment / 100);
              cashFlows.push({ month: month, amount: currentMonthAmount });
              $(`#product-calc-body-${productId}`).append(
                installmentRowContent(
                  month,
                  currentDate,
                  currentMonthAmount,
                  productId
                )
              );
            } else {
              if (lastInstallment && month == installments) {
                let currentMonthAmount =
                  productFinalPrice * (lastInstallment / 100);
                cashFlows.push({ month: month, amount: currentMonthAmount });
                $(`#product-calc-body-${productId}`).append(
                  installmentRowContent(
                    month,
                    currentDate,
                    currentMonthAmount,
                    productId
                  )
                );
              } else {
                var dateInstallmentAmount = 0;
                dateInstallmentAmount = calcInstallmentAmount(
                  evaluableProductFinalPrice,
                  foot.amount,
                  acountableInstallments,
                  month
                );
                cashFlows.push({ month: month, amount: dateInstallmentAmount });
                $(`#product-calc-body-${productId}`).append(
                  installmentRowContent(
                    month,
                    currentDate,
                    dateInstallmentAmount,
                    productId
                  )
                );
              }
            }
            bindChangeMonthAmountFor(month, productId);
          }
        }
        changeNvpDisplay(
          cashFlows.map((obj) => obj.amount),
          productId,
          true
        );
      }
      changeNvpDisplay(
        cashFlows.map((obj) => obj.amount),
        productId
      );
      localStorage.setItem("cashFlows", JSON.stringify(cashFlows));
    }
  };

  const bindChangeMonthAmountFor = (month, productId) => {
    $(`.product-${productId}-payment-month-${month}`).on("keydown", (event) => {
      if (event.which === 13 || event.which === 9) {
        const productId = event.target.dataset.product;
        const installments = $(`.product-installments-${productId}`).val();
        const currentMonth = event.target.dataset.month;
        const amount = parseFloat($(event.target).val());
        changeInstallmentsDistribution(
          true,
          installments,
          productId,
          currentMonth,
          amount,
          null,
          null,
          null
        );
        if (event.which === 13) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    });
    $(`.product-${productId}-payment-month-${month}`).on("change", (event) => {
      const productId = event.target.dataset.product;
      const installments = $(`.product-installments-${productId}`).val();
      const currentMonth = event.target.dataset.month;
      const amount = parseFloat($(event.target).val());
      changeInstallmentsDistribution(
        true,
        installments,
        productId,
        currentMonth,
        amount,
        null,
        null,
        null
      );
    });
  };

  const calcMortgageCredit = (years, totalValue, totalFoot) => {
    const totalMonths = years * 12;
    return (
      ((totalValue - totalFoot) *
        (0.00375 * Math.pow(1 + 0.00375, totalMonths))) /
      (Math.pow(1 + 0.00375, totalMonths) - 1)
    );
  };

  const validateMinFoot = (product) => {
    var valid = true;
    // Product payment min foot is defined by just the amount of months until delivered.
    // This means, since the installments are now incremented by the seller, the value
    // must be read directly from the project.
    // const installments = $(`.product-installments-${product.id}`).val();
    const installments = product.construction.remaining_months_till_deliver;
    if (product) {
      var installmentsSum = 0.0;
      if (parseFloat(installments) === 1) {
        for (var month = 1; month <= installments; month++) {
          const currentInstallment = $(
            `.product-${product.id}-payment-month-${month}`
          ).val();
          installmentsSum = installmentsSum + parseFloat(currentInstallment);
        }
      } else {
        for (var month = 1; month < installments; month++) {
          const currentInstallment = $(
            `.product-${product.id}-payment-month-${month}`
          ).val();
          installmentsSum = installmentsSum + parseFloat(currentInstallment);
        }
      }

      const productFinalPrice = $(`.product-sale-price-${product.id}`).val();
      installmentsSum =
        installmentsSum +
        parseFloat($(`.product-${product.id}-reserve-amount`).val());
      const productFootPercent = (100 / productFinalPrice) * installmentsSum;
      if (
        productFootPercent <
        product.construction.project.minimum_foot_percent - 0.5
      ) {
        M.toast({
          html: "Las cuotas del plan de pago suman menos del 20% del valor.",
        });
        valid = false;
      }
    }
    return valid;
  };

  const validateVNAdifference = (product) => {
    var valid = true;
    if (product) {
      const remain = $(
        `.product-total-installment-remain-price-${product.id}`
      ).val();
      if (Number(remain.replace(/[^0-9.-]+/g, "")) < 0.0) {
        M.toast({
          html: "Error. El valor por flujo de VNA2 (propuesta) es inferior al flujo VNA1 (base).",
        });
        valid = false;
      }
    }
    return valid;
  };

  const validateCustomerPrecense = () => {
    var valid = true;
    const customerEmail = $("#customer-email").val();
    if (!customerEmail || customerEmail === "") {
      M.toast({
        html: "Error. Falta información del cliente",
      });
      valid = false;
    }
    return valid;
  };

  $(".quotation-finish").on("click", (e) => {
    e.preventDefault();
    const quotationForm = document.getElementById("quotation-form");
    const productsQuotated = JSON.parse(
      localStorage.getItem("productsQuotated")
    );
    const principalProduct = productsQuotated.find((product) => {
      return ["house", "apartment"].includes(product.category.name);
    });
    if (
      validateMinFoot(principalProduct) &&
      validateVNAdifference(principalProduct) &&
      validateCustomerPrecense()
    ) {
      quotationForm.submit();
    }
  });

  setTimeout(() => {
    if (
      $("#construction-select").val() != 0 &&
      $("#construction-select").val() != "0" &&
      $("#construction-select").val() != null &&
      $("#construction-select").val() != undefined
    ) {
      searchForProductsData($("#construction-select").val(), true);
    }
  }, 200);

  const recalcInstallmentRows = (currentDate, productId) => {
    const installments = $(`.product-installments-${productId}`).val();
    const diffBetweenMonths = monthDiff(
      new Date(localStorage.getItem("current_date")),
      currentDate
    );
    changeInstallmentsDistribution(
      false,
      installments - diffBetweenMonths,
      productId,
      0,
      0,
      currentDate,
      null,
      null
    );
  };

  const productDateDistributionChanger = (event, productId) => {
    let inputValue = event.target.value;
    if (event.which === 13 || (event.which === 9 && mobileAndTabletCheck())) {
      let currentDate = new Date(Date.parse(inputValue + " 01:00:00"));
      localStorage.setItem("current_date", currentDate);
      recalcInstallmentRows(currentDate, productId);
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const triggerAmountChangerOfProduct = (productId, input) => {
    var listOfInputs = $(".product-" + productId + "-payment-month");
    var ev = document.createEvent("Event");
    ev.initEvent("keydown");
    ev.which = ev.keyCode = 13;
    var lastInput = input ? input : listOfInputs[listOfInputs.length - 1];
    lastInput.dispatchEvent(ev);
  };

  const productInstallmentAmountChanger = (event, productId) => {
    var fromMonth = parseInt(
      $("#installment-from-month-product-" + productId).val()
    );
    var tillMonth = parseInt(
      $("#installment-till-month-product-" + productId).val()
    );
    var amountPerMonth = parseFloat(
      $("#per-installment-amount-product-" + productId).val()
    );
    if (event.which === 13 || (event.which === 9 && mobileAndTabletCheck())) {
      var listOfInputs = $(".product-" + productId + "-payment-month");
      $.each(listOfInputs, (_, amountField) => {
        var dataMonth = parseInt(amountField.dataset.month);
        if (dataMonth >= fromMonth && dataMonth <= tillMonth) {
          $(amountField).val(amountPerMonth);
          triggerAmountChangerOfProduct(productId, amountField);
        }
      });
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const productModifyMinFootPercent = (productId, firstInstallment) => {
    var productsQuotated = JSON.parse(
      window.localStorage.getItem("productsQuotated")
    );
    let product = null;
    $.each(productsQuotated, (_, thisProduct) => {
      if (thisProduct.id == productId) {
        product = thisProduct;
        thisProduct.construction.project.minimum_foot_percent =
          parseInt(firstInstallment);
      }
    });
    window.localStorage.setItem(
      "productsQuotated",
      JSON.stringify(productsQuotated)
    );
    return product;
  };

  const changeInstallmentsBySimpleNewFormula = (product, firstInstallment) => {
    var productFinalPrice = $(`.product-sale-price-${product.id}`).val();
    var selectedFootType = selectedKindOfFootFor(product.id);
    var foot = calcFoot(
      productFinalPrice,
      productFinalPrice * (firstInstallment / 100),
      selectedFootType
    );
    $(`.product-foot-${selectedFootType}-${product.id}`).val(
      selectedFootType == "percent" ? foot.percent : foot.amount
    );
    changeInstallmentsDistribution(
      false,
      product.construction.remaining_months_till_deliver,
      product.id,
      0,
      0,
      new Date(localStorage.getItem("current_date")),
      null,
      null
    );
  };

  const changeInstallmentsByComplexNewFormula = (
    productId,
    firstInstallment,
    lastInstallment
  ) => {
    let product = findInStorage("products", "id", productId);
    changeInstallmentsDistribution(
      false,
      product.construction.remaining_months_till_deliver,
      product.id,
      0,
      0,
      new Date(localStorage.getItem("current_date")),
      firstInstallment,
      lastInstallment
    );
    triggerAmountChangerOfProduct(productId, null);
  };

  const productInstallmentsPercentDistributionChanger = (event, productId) => {
    let newPercents = $(
      "#installments-percent-distribution-changer-" + productId
    ).val();
    if (event.which === 13 || (event.which === 9 && mobileAndTabletCheck())) {
      let [firstInstallment, _installmentsPercent, lastInstallment] =
        newPercents.split("-");
      if (lastInstallment) {
        changeInstallmentsByComplexNewFormula(
          productId,
          firstInstallment,
          lastInstallment
        );
      } else {
        let product = productModifyMinFootPercent(productId, firstInstallment);
        changeInstallmentsBySimpleNewFormula(product, firstInstallment);
      }
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const changedReserveAmount = (event, productId) => {
    if (event.which === 13 || event.which === 9) {
      changeNvpDisplay(
        JSON.parse(localStorage.getItem("cashFlows")).map((obj) => obj.amount),
        productId
      );
      triggerAmountChangerOfProduct(productId, null);
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
});
