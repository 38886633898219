export const serverRequest = (
  url,
  method,
  params,
  body,
  dataType,
  contentType
) => {
  dataType = dataType == null ? "json" : dataType;
  contentType =
    contentType == null ? "application/json; charset=utf-8" : contentType;

  var defer = $.Deferred();

  $.ajax({
    url: url,
    type: method,
    dataType: dataType,
    contentType: contentType,
    data: params,
    params: JSON.stringify(body),
  })
    .then(
      function fulfillHandler(data) {
        defer.resolve(data);
      },
      function rejectHandler(jqXHR, textStatus, errorThrown) {
        defer.reject(errorThrown);
      }
    )
    .catch(function errorHandler(error) {
      defer.reject(error);
    });

  return defer.promise();
};
