import { mobileAndTabletCheck } from "packs/lib/deviceable";

document.addEventListener("DOMContentLoaded", function () {
  localStorage.setItem("deedCustomers", []);
  localStorage.setItem("deed_customer_email", $("#deed-customer-email").val());
  localStorage.setItem(
    "deed_customer_cellphone",
    $("#deed-customer-cellphone").val()
  );
  localStorage.setItem("deed_customer_dni", $("#deed-customer-dni").val());

  $(".deed-customer-find").on("keydown", (event) => {
    if (event.which === 13 || (event.which === 9 && mobileAndTabletCheck())) {
      const deedFindCustomerForm = document.getElementById(
        "find-customers-promises"
      );
      deedFindCustomerForm.submit();
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  });
});
