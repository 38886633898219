import { serverRequest } from "packs/lib/callbacable";

document.addEventListener("DOMContentLoaded", function () {
  $(".notification-link").click(function (evt) {
    evt.preventDefault();

    let $self = $(this);
    let userNotificationId = $self.data().notificationId;
    let currentEntityId = $self.data().currentEntityId;
    let body = {
      sent_notification_id: userNotificationId,
      current_entity_id: currentEntityId,
    };
    serverRequest(
      "/notifications/read_notification",
      "GET",
      body,
      {},
      null,
      null
    ).then(
      (_data) => {
        window.open($self.attr("href"), "_blank").focus();
      },
      (_error) => {
        M.toast({ html: "No se pudo acceder al detalle de la notificación." });
      }
    );
  });
});
