import { serverRequest } from "packs/lib/callbacable";
import { mobileAndTabletCheck } from "packs/lib/deviceable";

document.addEventListener("DOMContentLoaded", function () {
  localStorage.setItem("promiseCustomers", []);
  localStorage.setItem(
    "promise_customer_email",
    $("#promise-customer-email").val()
  );
  localStorage.setItem(
    "promise_customer_cellphone",
    $("#promise-customer-cellphone").val()
  );
  localStorage.setItem(
    "promise_customer_dni",
    $("#promise-customer-dni").val()
  );

  var elems = document.getElementById("add_customer_modal");
  var instances = M.Modal.init(elems, {});

  var elems = document.getElementById("add_payment_modal");
  var instances = M.Modal.init(elems, {});

  $(".promise-customer-find").on("keydown", (event) => {
    if (event.which === 13 || (event.which === 9 && mobileAndTabletCheck())) {
      const promiseFindCustomerForm = document.getElementById(
        "find-customers-reserves"
      );
      promiseFindCustomerForm.submit();
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  const addPromiseCustomer = (customerData) => {
    let promiseCustomers = JSON.parse(localStorage.getItem("promiseCustomers"));
    promiseCustomers.push(customerData);
  };

  const searchForPromiseCustomerData = (field, value, promiseId) => {
    serverRequest(
      "/promises/search_customer_by",
      "GET",
      { field: field, value: value, promise_id: promiseId },
      {},
      "script",
      false
    ).then(
      (data) => {
        $("select").sm_select();
      },
      (error) => {}
    );
  };

  // Execute a function when the user releases a key on the keyboard
  $(".promise-customer-searcher").on("keydown", function (event) {
    let inputValue = event.target.value;
    const searchField = event.target.dataset.searchField;
    const promiseId = event.target.dataset.promiseId;

    let currentCustomerFieldData = localStorage.getItem(
      `promise_customer_${searchField}`
    );

    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
      if (currentCustomerFieldData !== inputValue) {
        localStorage.setItem(`promise_customer_${searchField}`, inputValue);
        searchForPromiseCustomerData(searchField, inputValue, promiseId);
      }
    }
  });
});
