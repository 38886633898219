import { checkPasswordStreng } from "packs/lib/password_checkable";
import { stickyAlert } from "packs/components/validations/sticky_messages";

document.addEventListener("DOMContentLoaded", function () {
  var options = {
    edge: "left",
  };
  var elems = document.querySelectorAll(".sidenav");
  M.Sidenav.init(elems, options);
  var elems = document.querySelectorAll(".sidenav-left");
  M.Sidenav.init(elems, {
    edge: "left",
    preventScrolling: false,
  });
  var elems = document.querySelectorAll(".sidenav-right");
  M.Sidenav.init(elems, { edge: "right" });
  var elems = document.querySelectorAll("select");
  var instances = M.FormSelect.init(elems, {});
  var elems = document.querySelectorAll(".materialboxed");
  var instances = M.Materialbox.init(elems, {});
  var elems = document.querySelectorAll(".collapsible");
  var instances = M.Collapsible.init(elems, {
    accordion: false,
  });
  var elems = document.querySelectorAll(".datepicker");
  var instances = M.Datepicker.init(elems, {
    format: "dd-mm-yyyy",
  });
  var elems = document.querySelectorAll(".dropdown-trigger");
  var instances = M.Dropdown.init(elems, {});
  var elems = document.querySelectorAll(".tabs");
  var instances = M.Tabs.init(elems, {});

  var elems = document.querySelectorAll(".tooltipped");
  var instances = M.Tooltip.init(elems, {
    enterDelay: 1,
    html: true,
  });

  const customFormValidationFunction = (stepperForm, activeStepContent) => {
    var valid = true;
    var inputs = activeStepContent.querySelectorAll("input, textarea, select");
    $(".sticky-message").remove().end();
    $.each(inputs, (_, input) => {
      if (!input.checkValidity()) {
        input.style.border = "1px solid red";
        var labelText = input.parentElement.children[0].innerHTML;
        var validationContainer =
          document.getElementsByClassName("stepper")[0].parentElement
            .parentElement;
        validationContainer.insertAdjacentHTML(
          "afterbegin",
          stickyAlert(
            labelText ? `Error en ${labelText}` : `Revisar campos resaltados`,
            "error"
          )
        );
        valid = false;
      }
    });
    return valid;
  };

  var stepper = document.querySelector(".stepper");
  if (stepper) {
    var stepperInstace = new MStepper(stepper, {
      validationFunction: customFormValidationFunction,
    });
  }
  var elems = document.querySelectorAll(".fixed-action-btn-sidenav");
  var instances = M.FloatingActionButton.init(elems, {
    hoverEnabled: false,
  });

  $(".modal").modal();

  $(".materialboxed").materialbox();

  $("select").sm_select();

  $(".ctc-button").on("click", (event) => {
    let targetField = event.target.dataset.targetField;

    if (targetField) {
      let copyText = document.getElementById(targetField);

      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      document.execCommand("copy");

      M.toast({ html: "Copiado al portapapeles" });
    }
  });

  checkPasswordStreng();
});
