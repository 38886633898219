export const paymentMethodListener = () => {
  $(".payment-method-to-listen").on("change", function (event) {
    let selectedOption = event.target.options[event.target.selectedIndex];
    if (selectedOption.dataset.extended.toString() === "true") {
      $(".specific-payment-method-fields").removeClass("hide");
    } else {
      $(".specific-payment-method-fields").addClass("hide");
    }
  });
};
