import { serverRequest } from "packs/lib/callbacable";
import { mobileAndTabletCheck } from "packs/lib/deviceable";
import { paymentMethodListener } from "packs/lib/hideable";

document.addEventListener("DOMContentLoaded", function () {
  localStorage.setItem("reserveCustomers", []);
  localStorage.setItem(
    "reserve_customer_email",
    $("#reserve-customer-email").val()
  );
  localStorage.setItem(
    "reserve_customer_cellphone",
    $("#reserve-customer-cellphone").val()
  );
  localStorage.setItem(
    "reserve_customer_dni",
    $("#reserve-customer-dni").val()
  );

  var elems = document.getElementById("add_customer_modal");
  var instances = M.Modal.init(elems, {});

  var elems = document.getElementById("add_payment_modal");
  var instances = M.Modal.init(elems, {});

  $(".reserve-customer-find").on("keydown", (event) => {
    if (event.which === 13 || (event.which === 9 && mobileAndTabletCheck())) {
      const reserveFindCustomerForm = document.getElementById(
        "find-customers-quotations"
      );
      reserveFindCustomerForm.submit();
    }
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  const addReserveCustomer = (customerData) => {
    let reserveCustomers = JSON.parse(localStorage.getItem("reserveCustomers"));
    reserveCustomers.push(customerData);
  };

  const searchForReserveCustomerData = (field, value, reserveId) => {
    serverRequest(
      "/reserves/search_customer_by",
      "GET",
      { field: field, value: value, reserve_id: reserveId },
      {},
      "script",
      false
    ).then(
      (data) => {
        $("select").sm_select();
      },
      (error) => {}
    );
  };

  // Execute a function when the user releases a key on the keyboard
  $(".reserve-customer-searcher").on("keydown", function (event) {
    let inputValue = event.target.value;
    const searchField = event.target.dataset.searchField;
    const reserveId = event.target.dataset.reserveId;

    let currentCustomerFieldData = localStorage.getItem(
      `reserve_customer_${searchField}`
    );

    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
      if (currentCustomerFieldData !== inputValue) {
        localStorage.setItem(`reserve_customer_${searchField}`, inputValue);
        searchForReserveCustomerData(searchField, inputValue, reserveId);
      }
    }
  });

  paymentMethodListener();
});
